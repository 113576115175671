body {
    /* background: #003263;
    margin: 0;
    padding: 0;
    overflow-y: hidden; */

    background: rgba(231, 238, 245, 1);
	text-align: center;
	box-sizing: border-box;
	font-family: sans-serif;
	color: rgba(255, 255, 255, .8);
	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
	
}

.box {
	display: inline-block;
    font-size: 30px;
	vertical-align: top;
	transition: .3s color, .3s border, .3s transform, .3s opacity;

	
}

.loader-main{
    position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

[class*="loader-"] {
	display: inline-block;
	width: 1em;
	height: 1em;
	color: inherit;
	vertical-align: middle;
	pointer-events: none;
}

/* .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.bar, .bar2, .bar3, .bar4 {
    height: 125px;
    width: 25px;
    position: relative;
    animation: load 1.5s infinite;
}
@keyframes load {
    50% {
        transform: scaleY(1.5);
   }
}
.bar {
    background: #e74c3c;
    top: 150px;
    right: 200px;
}
.bar2 {
    background: #3498db;
    top: 30px;
    right: 150px;
    animation-delay: 0.3s;
}
.bar3 {
    background: #2ecc71;
    bottom: 90px;
    right: 100px;
    animation-delay: 0.6s;
}
.bar4 {
    background: #f1c40f;
    bottom: 210px;
    right: 50px;
    animation-delay: 0.9s;
} */

.loader-12 {
	position: relative;
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		background-color: #003263;
		left: 50%;
		right: 0;
		top: 0;
		bottom: 50%;
		box-shadow: -.5em 0 0 #003263;
		animation: loader-12 1s linear infinite;
	}
	&:after {
		top: 50%;
		bottom: 0;
		animation-delay: .25s;
	}
}

@keyframes loader-12 {
	0%,
	100% {
		box-shadow: -.5em 0 0 transparent;
		background-color: #003263;
	}
	50% {
		box-shadow: -.5em 0 0 #003263;
		background-color: transparent;
	}
}
