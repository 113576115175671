.App {
  text-align: center;
  background:#E7EEF5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import url(../src/pages/css/globel.css);
body{
    margin: 0;
    padding: 0;
    background:#E7EEF5 !important;
    color:var(--textColor);
}


/*+++++++++++++++++++++++++++ header style start +++++++++++++++++++++++*/
*{
  margin: 0;
  padding: 0;
}

.navbar-cont{
  margin-bottom: 100px;
}

p{
  font-size: 1rem;
  line-height: 1.6rem;
  margin-bottom: 0;
  color:var(--textColor);
}

label{
  color: var(--textColor);
  font-size: 1rem;
  font-weight: 500;
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="phone"],
input[type="tel"],
input[type="text"],
textarea {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  color: var(--primaryColor);
  font-size: 0.8rem !important;
  font-style: normal !important;
  line-height: 1.4rem !important;
  padding: 16px 8px !important;
  align-items: center;
  border-radius: 8px !important;
  border: 1px solid rgba(132, 144, 155, 1) !important;
  background: rgba(255, 255, 255,1) !important;
  position: relative;
  height: auto !important;
}

input[type="email"]:focus-visible,
input[type="number"]:focus-visible,
input[type="password"]:focus-visible,
input[type="phone"]:focus-visible,
input[type="tel"]:focus-visible,
input[type="text"]:focus-visible,
textarea:focus-visible
{
  outline: none !important;
}

select{
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  color: var(--primaryColor);
  font-size: 0.8rem !important;
  font-style: normal !important;
  line-height: 1.4rem !important;
  padding: 16px 8px !important;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--primaryColor);
  background-color: #E3ECF5 !important;
  position: relative;
}

input[type="email"]:focus-visible ,
input[type="number"]:focus-visible ,
input[type="password"]:focus-visible ,
input[type="phone"]:focus-visible ,
input[type="tel"]:focus-visible ,
input[type="text"]:focus-visible ,
textarea:focus-visible {
  border: 1px solid var(--primaryColor);
}



.generator_steps_container label {
  color: var(--text-grey1);
  font-size: 0.8rem;
  line-height: 1.2rem;
}

input[type="checkbox"],
.form-inputcheck {
  cursor: pointer;
  height: 1em !important;
  margin-right: 10px;
  min-width: 1em !important;
  opacity: 1;
  position: relative;
  border: 1px solid rgba(72, 72, 72, 1);
  background: rgba(255, 255, 255, 0.05) !important;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
}

input[type="checkbox"]:disabled {
  cursor: no-drop;
}

.form-inputcheck:checked,
input[type="checkbox"]:checked {
  -webkit-appearance: none;
  appearance: none;
  background: var(--primaryColor)
    url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e") !important;
  border: none;
}

input[type="radio"],
.form-inputradio {
  cursor: pointer;
  height: 1.5em !important;
  margin-right: 10px;
  min-width: 1.5em !important;
  opacity: 1;
  position: relative;
  border: 0.5px solid rgb(0, 50, 99);
  background: rgba(255, 255, 255, 0.05) !important;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
}

.form-inputradio:checked,
input[type="radio"]:checked {
  -webkit-appearance: none;
  appearance: none;
  background: var(--primaryColor)
    url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e") !important;
  border: none;
}




.secondary-txt{
  color:var(--inputText);
}


/* .marked{
  color:#F47878;

} */

a{
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color:var(--primaryColor) !important;
  text-decoration: none !important;
}

.marked{
  color:#F47878;
}

.form_primary_btn{
	border-radius: 8px !important;
	color:var(--white);
	background: var(--primaryColor);
	padding: 8px;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border:none !important;
  }
  
  .form_primary_btn:hover
  {
	background:#c2cede;
	color:var(--primaryColor) !important;
  }

  .soberiety_primaary_btn{
    background: var(--darkblue-clr);
    color: var(--white);
    padding: 16px !important;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
    letter-spacing: 1px;
    max-width: 400px;
    font-weight: 500;
  }
  

  .soberiety_primaary_btn:hover{
    background:#c2cede;
	  color:var(--primaryColor) !important;
  }

  .soberiety_primaary_btn.bg-transparent{
    color: var(--darkblue-clr) !important;
  }

  .soberiety_primaary_btn.bg-transparent:hover{
    text-decoration: underline;

  }

  .spinner {
    border: 4px solid rgb(141, 141, 141); /* Light grey border for the spinner */
    border-top: 4px solid #fff; /* White part of the spinner */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    
  }
  
  .loading {
    position: relative;
    padding: 10px auto !important;
    margin: 0 auto;
    height: 58px;
  }
  
  .loading .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px; /* Half of height */
    margin-left: -12px; /* Half of width */
  }
  

  .custom_white_bg {
    background: var(--white);
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 8px;
}

h2.page-heading{
  color:var(--primaryColor) !important;
  font-size: 30px !important;
  font-weight: 700;
}