/* @import url(../font/fonts.css);
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
/*++++++++++++++++++++++++++++++++++++++++++++++++ 
# Variables Start  
++++++++++++++++++++++++++++++++++++++++++++++++++*/
:root {
    --primaryColor:#003263;
    
    --secondayColor:#8491A0;
    --textColor:#092138;
    --secondayColorDark:#051522;
    --white:#ffffff;
    --lt-grey:#E3ECF5;
    --lightBg:#EEF3F8;
    --darkBg:#051522;
    --navColor:#444444;
    --bodyText:#777777;
    --headingColor:#3E3E3E;
    --inputText:#84909B;

    --white: #ffffff;
    --base-font:#092138;
    --label-font:14px;
    --font-h2:24px;
    --darkblue-clr:#003263;
    --font-16:16px;
    --card-bg:#E3ECF5;
    --card-border-clr: #CFD8E1;
    --card-price-font:32px;
    --form-placeholder:#84909B;
    --darkBlue2:#1D3A70;
    --primaryFont:'Raleway', sans-serif;
    --navbarBG:url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 8.28571C9.5 7.57566 10.0821 7 10.8 7H27.7C28.4179 7 29 7.57566 29 8.28571C29 8.99577 28.4179 9.57143 27.7 9.57143H10.8C10.0821 9.57143 9.5 8.99572 9.5 8.28571ZM27.7 14.7143H4.3C3.58205 14.7143 3 15.29 3 16C3 16.7101 3.58205 17.2857 4.3 17.2857H27.7C28.4179 17.2857 29 16.7101 29 16C29 15.29 28.4179 14.7143 27.7 14.7143ZM27.7 22.4286H16C15.2821 22.4286 14.7 23.0042 14.7 23.7143C14.7 24.4243 15.2821 25 16 25H27.7C28.4179 25 29 24.4243 29 23.7143C29 23.0042 28.4179 22.4286 27.7 22.4286Z" fill="white"/></svg>');
    --navbarClose:url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 13L13 1M1 1L13 13" stroke="%23000110" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

