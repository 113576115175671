@import url(./globel.css);

.soberiety_nav a{
  color:#092138;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  margin: 0 25px;
}

.first-section{
   background: var(--lightBg) url('../../assets/img/Lines.svg') no-repeat;
   background-position: left top;
   background-size: cover;
  /*  padding-bottom: 210px;*/
   padding-bottom: 200px;
   padding-top: 100px;
   margin-top: 0px;
}

.future_section{
  padding-bottom: 50px;
}
.first-section h1{
  font-size: 3.6rem;
  line-height: 4rem;
  font-weight: 900;
  color: var(--primaryColor);
}

.primary_btn{
  border-radius: 50px;
  color:var(--white) !important;
  background: var(--primaryColor) !important;
  padding: 8px 30px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border:none !important;
}

.primary_btn:hover, .primary_btn:active, .primary_btn.active
{
  background:#c2cede !important;
  color:var(--primaryColor) !important;
}

.primary_btn.extra{
  padding: 15px 30px;
}

.secondary_btn{
  border-radius: 50px;
  color: var(--primaryColor);
  background: var(--white);
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border:none !important;
}

.secondary_btn:hover{
  color: var(--primaryColor);
  background: var(--white);
}

/* .secondary_btn:hover
{
  background:#c2cede;
  color:var(--primaryColor);
} */

a.primary_btn{
  padding: 8px 30px !important;
  line-height: 1rem !important;
  color: #fff !important;
  padding: 15px 30px  !important;
}


.white{
  color: var(--white);
}

.dark-text{
  color:var(--textColor);
}

.tag_grey{
  background: 
  rgba(0, 58, 116, 0.2);
  color:var(--primaryColor);
  padding:8px 30px;
  border-radius: 50px;
  max-width:420px;
  margin:15px auto;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.featuresmain{
  background: url("../../assets/img/Shape-right-bottom.svg") right bottom no-repeat, url("../../assets/img/Shapes-left-top.svg") left top no-repeat 
  #003263;
  background-repeat: no-repeat;
  border-radius: 25px;
}

.featuresmain h3{
  color:var(--white);
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 800;
}

.featuresmain h6{
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
  color:var(--white);
  margin: 10px 0 15px 0;
}

.efficiency_row{
  background: #003263;
}

.efficiency_row .efficient-head{
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.1rem;
  color:var(--white);
  margin-bottom: 1.6rem;
}

.efficiency_row .image_cont{
  height: 100%;
  width: 100%;
  background: url("../../assets/img/Picture.png") no-repeat center center;
  background-size: cover;
}



.founder-story{
  background: #174675;
  border-top: solid 1px #fff;
}

.founder-story h4{
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  color:var(--white);
}
.whatmore .slide_head{
  font-size: 52px;
  font-weight: 900;
  color:var(--primaryColor);
  line-height: 62px;
}

.whatmore .slide-description{
  font-size: 16px;
  font-weight: 400;
  color:#092138;
  line-height: 26px;
}

.whatmore .slick-dots{
  position:absolute;
  width:auto;
  left:0;
  bottom:15px;
}

.whatmore .slick-dots li{
  margin: 0;
}

.whatmore .slick-dots li.slick-active button:before{
  display: none;
}

.whatmore .slick-dots li.slick-active{
  margin:05px;
}

.whatmore .slick-dots li.slick-active button{
  background: rgba(0, 50, 99, 1);
  border-radius: 20px;
  height: 10px;
  margin-right: 20px;
}

.whatmore .slick-dots li button:before{
  font-size: 10px;
  color:rgba(0, 50, 99, 0.8)
}

.getStarted{
  background: url("../../assets/img/Iphones.png") right bottom no-repeat, #003263;
  min-height: 660px;
}

.getStarted h3{
  font-size: 52px;
  font-weight: 900;
  color:var(--white);
  line-height: 62px;

}

.getStarted p{
  font-size: 16px;
  font-weight: 400;
  color:#ffffff;
  line-height: 26px;
}

.footer .blue-bg{
  background: var(--primaryColor);
  color:rgba(9, 33, 56, 1);
  font-size: 0.8rem !important;
}

.footer-nav{
  padding: 0;
  list-style-type: none;
}


.footer-nav li{
  border-right: 1px solid #fff;
  line-height: 12px;
  padding-right: 10px;
}

.footer-nav li a{
  color:#fff !important;
  text-decoration: none !important;
}

.footer-nav li:last-child{
  border-right: 0;
  padding-right: 0px;
}

/* Default navbar styles */
.navbar-custom {
  padding-bottom: 15px;
  background: transparent;
  transition: background 0.3s;
}

/* Navbar styles when scrolled */
.navbar-custom.scrolled {
  background: rgba(231, 238, 245, 1);/* Change this to your desired color when scrolled */
}

.accuracy-section{
  position: absolute;
   bottom: -150px;
}

.featuresmain .col-md-3 img{
  margin: 0 auto;
}

.user_profile_header {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border:solid 1px #003263;
  /* background: #003263; */
}

.profile-dropdown .dropdown-toggle::after{
  right: -12px;
  top: 50%;
  position: absolute;
}

.profile-dropdown .dropdown-menu {
  left: auto;
  right: 0;
  min-width: 230px;
  border-radius: 12px;
  border: 0 none;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1019607843);
}

.profile-dropdown .dropdown-menu a{
  margin: 10px 0;
}

.profile-dropdown .dropdown-menu .dropdown-item.active, .profile-dropdown .dropdown-menu .dropdown-item:active{
  background-color: var(--bs-dropdown-link-hover-bg);
}

.small-gap{
  line-height: 18px;
}

.color-primary{
  color:var(--primaryColor)
}


@media (max-width: 575.98px) { 
  
 }


@media (max-width: 767.98px) { 
  .getStarted {
    min-height: 450px;
    background-size: 60%;
    background: url(../../assets/img/getstartedIphones.png) right bottom no-repeat, #003263;
  }

  .accuracy-section{
    position: relative;
    bottom: 0;
  }

  .navbar-custom.scrolled {
    background: rgba(231, 238, 245, 1);/* Change this to your desired color when scrolled */
  }

  .navbar-cont{
    margin-bottom: 0px;
    z-index: 99;
    padding-bottom:30px;
  }

  .getStarted h3 {
    font-size: 40px;
    line-height: 48px;
  }

  .whatmore .slide_head{
    font-size: 40px;
    line-height: 48px;
  }

  .founder-story .container{
    padding: 0;
  }

  .header
  {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .header h1{
    font-size: 3rem;
  }

  .first-flow-info{
    margin-top: 70px !important;
    padding-top: 0 !important;
  }

  .tag_grey{
    font-size: 0.8rem;
    padding: 8px 20px;
  }

  .featuresmain{
    background: url("../../assets/img/phone_Shape_Bottom.png") right bottom no-repeat, url("../../assets/img/phoneShape_Top.png") left top no-repeat 
    #003263;  }

  .slick-slide .col-lg-7{
    min-height: 300px;
  }



  .slick-slide .col-lg-5{
    margin-top: 30px;
  }
  
  .slick-slide .col-lg-5 img{
    width:70%;
  }

  .whatmore .slick-dots{
    bottom:-15px;
    width:100%;
  }
  .navbar-custom{
    background: rgba(231, 238, 245, 1);
    z-index: 9999;
    /* padding-bottom: 30px; */
    text-align: start;
    .primary_btn{
      width:calc(100% - 30px);
      margin-top: 15px;
      margin-left: 15px !important;
    }
    .soberiety_nav {
      a{
        margin-left: 15px
      }
    }

  }

  .navbar-collapse.show{
    background: rgba(231, 238, 245, 1);
    z-index: 9999;
    
  }

 }


@media (max-width: 991.98px) { 

 }


@media (max-width: 1199.98px) {

 }


@media (max-width: 1399.98px) { 

 }

