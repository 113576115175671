.navbar-custom{
    // position:relative;
    .navbar-toggler{
        position: absolute;
        right:100px;
        top:20px
    }
    .profile-dropdown-main{
        position: relative;
        
        z-index: 9999;
        // width:44px;
    }

}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {  }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .navbar-custom{
       
        
        .profile-dropdown-main{
            position: absolute;
            right:30px;
            top: 5px;
            z-index: 9999;
            // width:44px;
        }
    
    }
 }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {  }

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { }